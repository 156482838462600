import Link from 'next/link';

import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

import {
  Container,
  Columns,
  FooterTopColumn,
  SocialMedia,
  Wrapper,
} from './styles';

import Logo from '../../../../images/components/Footer/logo.svg';

const FooterTop = (): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Logo />
        <Columns>
          <FooterTopColumn>
            <p>informações</p>
            <ul>
              <li>
                <Link passHref href="/conheça-a-delps">
                  <a>Conheça a Delps</a>
                </Link>
              </li>
              <li>
                <Link passHref href="/cidades-atendidas">
                  <a>Cidades atendidas</a>
                </Link>
              </li>
              <li>
                <Link passHref href="/filiais">
                  <a>Filiais</a>
                </Link>
              </li>
              <li>
                <Link passHref href="/cargas-de-risco">
                  <a>Cargas de risco</a>
                </Link>
              </li>
            </ul>
          </FooterTopColumn>
          <FooterTopColumn>
            <p>Recursos</p>
            <ul>
              <li>
                <Link passHref href="/rastreamento">
                  <a>Rastreamento</a>
                </Link>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=556235861151&text=Preciso%20de%20uma%20cota%C3%A7%C3%A3o%20de%20frete"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fazer cotação
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=556299851496&text=Preciso%20marcar%20uma%20coleta"
                  target="_blank"
                  rel="noreferrer"
                >
                  Solicitar coleta
                </a>
              </li>
              <li>
                <a
                  href="https://ssw.inf.br/2/servico?id=53&"
                  target="_blank"
                  rel="noreferrer"
                >
                  2ª via de boleto
                </a>
              </li>
              <li>
                <a
                  href="https://ssw.inf.br/2/servico?id=51&"
                  target="_blank"
                  rel="noreferrer"
                >
                  Emitir DACTE
                </a>
              </li>
              <li>
                <a
                  href="https://ssw.inf.br/2/servico?id=52&"
                  target="_blank"
                  rel="noreferrer"
                >
                  Baixar XML
                </a>
              </li>
              {/* <li>
                <Link passHref href="/rastreamento/solicitar-visita">
                  <a>Solicitar visita (logistas)</a>
                </Link>
              </li> */}
            </ul>
          </FooterTopColumn>
          <FooterTopColumn>
            <p>atendimento</p>
            <ul>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=556235861151&text=Ol%C3%A1"
                  target="_blank"
                  rel="noreferrer"
                >
                  WhatsApp
                </a>
              </li>
              <li>
                <a href="tel:556235861151">Telefone</a>
              </li>
              <li>
                <Link passHref href="/filiais">
                  <a>Filiais</a>
                </Link>
              </li>
            </ul>
          </FooterTopColumn>
          <SocialMedia>
            <p>siga</p>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/delps_transportes/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram size={24} />
                </a>
              </li>
              <li>
                <a
                  href="https://web.facebook.com/falecomadelps/?_rdc=1&_rdr"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebook size={24} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/delps-transportes/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LinkedIn"
                >
                  <FaLinkedin size={24} />
                </a>
              </li>
            </ul>
          </SocialMedia>
        </Columns>
      </Wrapper>
    </Container>
  );
};

export default FooterTop;
