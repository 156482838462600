import Link from 'next/link';
import { useRouter } from 'next/router';

import { useHeader } from '../Provider';

import {
  AppsGridMobile,
  MenuMobileLink,
  Menu,
  MenuMobileContainer,
} from './styles';

import { BsGrid3X3GapFill } from 'react-icons/bs';

import Rastreamento from '../../../../images/icons/rastreamento';
import Cotacao from '../../../../images/icons/cotacao.svg';
import Coleta from '../../../../images/icons/coleta.svg';
import ViaBoleto from '../../../../images/icons/2-via-boleto.svg';
import Dacte from '../../../../images/icons/dacte.svg';
import XML from '../../../../images/icons/xml.svg';

const AppsMobileComponent = (): JSX.Element => {
  const { menuAppsMobileOpen, toggleAppsMobile } = useHeader();
  const { pathname } = useRouter();

  return (
    <MenuMobileContainer className={menuAppsMobileOpen ? 'open' : 'closed'}>
      <div className="top">
        <AppsGridMobile
          isOpen={menuAppsMobileOpen}
          className={menuAppsMobileOpen ? 'open' : 'closed'}
          onClick={toggleAppsMobile}
        >
          <BsGrid3X3GapFill size={25} />
        </AppsGridMobile>
      </div>
      {menuAppsMobileOpen && (
        <Menu isOpen={menuAppsMobileOpen}>
          <Link href="/rastreamento" passHref>
            <MenuMobileLink
              selected={pathname.includes('/rastreamento')}
              onClick={toggleAppsMobile}
            >
              <Rastreamento /> Rastreamento
            </MenuMobileLink>
          </Link>
          <hr />
          <MenuMobileLink
            href="https://api.whatsapp.com/send?phone=556235861151&text=Preciso%20de%20uma%20cota%C3%A7%C3%A3o%20de%20frete"
            target="_blank"
            rel="noreferrer"
          >
            <Cotacao /> Fazer cotação
          </MenuMobileLink>
          <hr />
          <MenuMobileLink
            href="https://api.whatsapp.com/send?phone=556299851496&text=Preciso%20marcar%20uma%20coleta"
            target="_blank"
            rel="noreferrer"
          >
            <Coleta /> Solicitar coleta
          </MenuMobileLink>
          <hr />
          <MenuMobileLink
            href="https://ssw.inf.br/2/servico?id=53&"
            target="_blank"
            rel="noreferrer"
          >
            <ViaBoleto /> 2ª via de boleto
          </MenuMobileLink>
          <hr />
          <MenuMobileLink
            href="https://ssw.inf.br/2/servico?id=51&"
            target="_blank"
            rel="noreferrer"
          >
            <Dacte /> Emitir DACTE
          </MenuMobileLink>
          <hr />
          <MenuMobileLink
            href="https://ssw.inf.br/2/servico?id=52&"
            target="_blank"
            rel="noreferrer"
          >
            <XML /> Baixar XML
          </MenuMobileLink>
        </Menu>
      )}
    </MenuMobileContainer>
  );
};

export default AppsMobileComponent;
